import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {WrapPageElementBrowserArgs} from 'gatsby';
import React, {cloneElement} from 'react';
import {WunderItems} from './src/components/WunderItems';
import {PageData} from './src/types/PageData';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import Navbar from './src/components/layout/Navigation/Navbar';
import { MDBContainer } from 'mdb-react-ui-kit';

library.add(fas);

export const wrapPageElement = ({element, props}: WrapPageElementBrowserArgs<PageData>) => {
    return (
        <div>
            <Navbar/>
            <MDBContainer className="my-4">
                {cloneElement(element, {
                    wunderItems: <WunderItems nodes={props.data.allWunderItem.nodes}/>
                })}
            </MDBContainer>
        </div>
    );
};