import {WunderContent} from './src/components/elements/WunderContent';
import {ReactComponentLike} from 'prop-types';

type ItemsConfig = {
    [className: string]: {
        fields: string[];
        component: ReactComponentLike;
    }
}

export const itemsConfig: ItemsConfig = {
    'CIC\\Pixelwunder\\Item\\Content\\WunderContent': {
        fields: ['content'],
        component: WunderContent
    },
    'CIC\\Pixelwunder\\Item\\Accordion\\WunderAccordion': {
        fields: [],
        component: WunderContent
    }
}