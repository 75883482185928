import React, {FC, ReactElement} from 'react';

export type WunderContentProps = {
    content: string;
};

export const WunderContent: FC<WunderContentProps> = ({content}): ReactElement => {

    return (
        <>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </>
    );
};