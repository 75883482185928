import React, {FC, ReactElement} from 'react';
import {WunderItemHolder} from './WunderItemHolder';

type WunderItemsProps = {
    nodes: ReadonlyArray<Queries.WunderItem>;
}

export const WunderItems: FC<WunderItemsProps> = ({nodes}): ReactElement => {
    return (
        <>
            {nodes.map(({className, props}, i) => (
                <>
                    {!className || !props ? (
                        <>className/props undefined</>
                    ) : (
                        <WunderItemHolder key={i} className={className} props={JSON.parse(props)}/>
                    )}
                </>
            ))}
        </>
    );
};