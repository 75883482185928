import React, {useState} from 'react';
import {MDBContainer, MDBNavbar, MDBNavbarToggler, MDBNavbarBrand, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn} from 'mdb-react-ui-kit';
import {graphql, Link, useStaticQuery} from 'gatsby';

type NavbarProps = {

};

const Navbar: React.FC<NavbarProps> = ({}): React.ReactElement => {
    const [showCollapse, setShowCollapse] = useState(false);
    const {allSitePage: {nodes: pages}} = useStaticQuery<Queries.Query>(graphql`
        query {
            allSitePage {
                nodes {
                    path
                    pageContext
                }
            }
        }
    `);

    return (
        <MDBNavbar expand="lg" light bgColor="light">
            <MDBContainer fluid>
                <MDBNavbarBrand href="/">Brand</MDBNavbarBrand>

                <MDBNavbarToggler
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setShowCollapse(!showCollapse)}
                >
                    <MDBIcon icon="bars" fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={showCollapse}>
                    <MDBNavbarNav className="d-flex justify-content-end mb-2 mb-lg-0">
                        {pages.map(page => (
                            <MDBNavbarItem>
                                <Link to={page.path}>
                                    <MDBNavbarLink aria-current="page">
                                        {page.pageContext.title}
                                    </MDBNavbarLink>
                                </Link>
                            </MDBNavbarItem>
                        ))}
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
};

export default Navbar;
