import React, {FC, useMemo} from 'react';
import {itemsConfig} from '../../items-config';

export type WunderItemProps = {
    className: string;
    props: object;
};

export const WunderItemHolder: FC<WunderItemProps> = ({className, props}) => {
    const Component = useMemo(() => itemsConfig[className].component, [className]);

    return (
        <div>
            {Component && <Component {...props}/>}
        </div>
    );
};